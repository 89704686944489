import React from 'react'
import {Menu, Dropdown} from 'antd';
import '../layout.css'
import styled from "styled-components";
import ArrowDrop from '../../assets/ArrowDrop.svg'
import {Link} from 'gatsby'


const Menu1 = styled(Menu)`
  background-color: #1c1a1a;
  padding: 0 45px 35px 25px;
  margin-top: 40px;
  margin-left: -180px;
`;

const Container = styled.div`
  text-align: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: end;
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;


const ContainerCategory = styled.div`
  //margin-bottom: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
    align-items: flex-start;
  }
`;

const ImgText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: uppercase;
`;

const TextDrop = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 46px;
  white-space: nowrap;
  /* identical to box height, or 329% */
  text-transform: uppercase;
  color: #FFFFFF !important;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
  @media (max-width: 1350px) {
    font-size: 13px;
  }
`;

const TextDrop1 = styled.span`
  padding-bottom: 41.5px;
  :hover {
    border-bottom: 2px solid #4285f4;
  }
`;


const CategoryDropdown = ({ data }) => {
    const menu = (
      <Menu1>
          <Container>
              <GridContainer>
                  {data && data.map((item, index) => {
                      return (
                        <div key={index}>
                            <Menu1.Item className='modified-item'>
                                <ContainerCategory >
                                    <Link to={item.link}>{item.img}</Link>
                                    <ImgText>{item.name}</ImgText>
                                </ContainerCategory>
                            </Menu1.Item>
                        </div>
                      )
                  })}
              </GridContainer>
          </Container>
      </Menu1>
    )

    return (
      <div style={{ paddingLeft: 15, paddingRight: 15, height: 50, zIndex: 1 }}>
        <Dropdown overlay={menu}>
          <a
            href="/"
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <TextDrop>
              <TextDrop1>Умный дом</TextDrop1>
              <ArrowDrop
                className="active"
                style={{ width: 8, height: 5, marginLeft: 5 }}
              />
            </TextDrop>
          </a>
        </Dropdown>
      </div>
    );
};

export default CategoryDropdown;
