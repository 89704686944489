import React from 'react'
import {Menu, Dropdown} from 'antd';
import '../layout.css'
import styled from "styled-components";
import ArrowDrop from '../../assets/ArrowDrop.svg'
import {Link} from 'gatsby'

const Menu1 = styled(Menu)`
  background-color: #1c1a1a;
  padding: 33px 40px 33px 40px;
  margin-top: 40px;
  margin-left: -40px;
`;

const Container = styled.div`
    text-align: center;
`;

const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: bottom;
`;


const ContainerCategory = styled.div`
    //margin-bottom: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
        display: block;
        align-items: flex-start;
    }
`;

const ImgText = styled(Link)`
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #FFFFFF;
    padding: 15px;
`;

const TextDrop = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 46px;
    white-space: nowrap;
    text-transform: uppercase;
    color: #FFFFFF !important;
    @media (max-width: 1600px) {
        font-size: 14px;
    }
    @media (max-width: 1350px) {
        font-size: 13px;
    }
`;

const TextDrop1 = styled.span`
  padding-bottom: 41.5px;
  :hover {
    border-bottom: 2px solid #4285f4;
  }
`;


const CompanyDropdown = ({ data }) => {
    const menu = (
      <Menu1>
          <Container>
              <GridContainer>
                  {data && data.map((item, index) => {
                      return (
                          <Menu1.Item
                            key={index + "comp"}
                            className="modified-item"
                            style={{ alignItems: "flex-start" }}
                          >
                            <ContainerCategory key={index}>
                              <ImgText to={item.url}>{item.name}</ImgText>
                            </ContainerCategory>
                          </Menu1.Item>
                      );
                  })}
              </GridContainer>
          </Container>
      </Menu1>
    )

    return (
      <div style={{ paddingLeft: 15, paddingRight: 15, height: 50, zIndex: 1 }}>
        <Dropdown overlay={menu}>
          <a
            href="/"
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <TextDrop>
              <TextDrop1>Компания</TextDrop1>{" "}
              <ArrowDrop
                className="active"
                style={{ width: 8, height: 5, marginLeft: 5 }}
              />
            </TextDrop>
          </a>
        </Dropdown>
      </div>
    );
};

export default CompanyDropdown;
