import React, { useState } from "react";
import "../layout.css";
import CategoryDropdown from "../Dropdown/category";
import styled from "styled-components";
import Logo from "../../assets/logo.svg";
import { Button, Menu } from "antd";
import CompanyDropdown from "../Dropdown/company";
import Calc from "../../assets/Calc.svg";
import MobileBtn from "../Dropdown/mobilebtn";
import { Link } from "gatsby";
import { ContainerHeader } from "../../ui/containers";
import ProjectDropdown from "../Dropdown/project";
import { graphql, useStaticQuery } from "gatsby";
import { ReactSVG } from "react-svg";

const { SubMenu } = Menu;

const HeaderContainer = styled.div`
  margin: 25px auto;
  display: flex;
  align-items: center;
  min-height: 50px;
  justify-content: space-between;
  @media (max-width: 1100px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
const ContainerBtn = styled.div`
  display: flex;
  align-items: baseline;
  @media (max-width: 800px) {
    display: none;
  }
`;

const Btn = styled(Button)`
  background-color: inherit !important;
  font-weight: 500;
  font-size: 14px;
  border: none;
  height: 50px !important;
  /* identical to box height, or 329% */
  padding: 0 !important;
  margin: 15px;
  white-space: nowrap;
  text-transform: uppercase;
  color: #FFFFFF;
  @media (max-width: 1600px) {
    font-size: 14px;
  }
  @media (max-width: 1350px) {
    font-size: 13px;
  }
`;

const CalcPrice = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  cursor:pointer;
`;

const TextCalc = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-right: 10px;
  text-align: center;
  color: #FFFFFF;
  @media (max-width: 1200px) {
    font-size: 10px;
    max-width: 80px;
  }
  @media (max-width: 1150px) {
    display: none;
  }
  @media (max-width: 1000px) {
    font-size: 10px;
    max-width: 80px;
    display: flex;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    max-width: 80px;
    display: none;
  }
`;

/*const ColorMenu = styled(SubMenu)`
  color: white !important;
  background-color: #4285F4 !important;
`;*/

const MenuGG = styled(Menu)`
  background-color: #4285F4;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ItemMenu = styled(Link)`
  width: 100%;
  background-color: #4285F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 !important;
  //padding-bottom: 25px;
  padding-top: 25px;
`;

const ItemMenu1 = styled(Link)`
  width: 100%;
  background-color: #4285F4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PodMenu = styled.div`
  width: 100%;
  background-color: #4285F4;
  margin-top: -5px;
  margin-bottom: -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PodItemMenu = styled(Menu.Item)`
  margin: 0;
  padding: 0;
  background-color: #4285F4;
  max-width: 180px;
  min-height: 25px;
  line-height: normal;
  text-align: center;
`;


const LINK3 = [
  {
    name: "О нас",
    url: "/about",
  },
  {
    name: "Сотрудничество",
    url: "/cooperation",
  },
  {
    name: "Контакты",
    url: "/contacts",
  }
];

const CategoryDropdownMobile = ({ title, data }) => {
  return (
    <PodMenu>
      {
        data && data.map((item, counter) =>
          <PodItemMenu key={item.name + counter} style={{ backgroundColor: "#4285F4" }}>
            <ItemMenu to={item.link}>{item.name}</ItemMenu>
          </PodItemMenu>
        )
      }
    </PodMenu>

  );
};

const ProjectsDropdownMobile = ({ title, data }) => {
  return (
    <PodMenu>
      {
        data && data.map((item, counter) =>
          <PodItemMenu key={item.name + counter} style={{
            margin: 0,
            padding: 0,
            backgroundColor: "#4285F4",
            maxWidth: 180,
            minHeight: 75,
            lineHeight: "normal",
            textAlign: "center"
          }}>
            <ItemMenu to={item.url}>{item.name}</ItemMenu>
          </PodItemMenu>
        )
      }
    </PodMenu>

  );
};

const CompanyDropdownMobile = ({ title, data }) => {
  return (

    <PodMenu>
      {
        data && data.map((item, counter) =>
          <PodItemMenu key={item.name + counter} style={{ backgroundColor: "#4285F4" }}>
            <ItemMenu to={item.url}>{item.name}</ItemMenu>
          </PodItemMenu>
        )
      }
    </PodMenu>
  );
};


const Svg = styled(ReactSVG)`
  width: 50px;
  margin-top: 24px;

  :hover {
    & > div > svg > path {
      fill: #4285F4;
      transition: all 0.5s ease-out;
    }
  }

  & > div > svg {
    width: 50px;
    margin-top: 24px;
  }

  & > div > svg > path {
    fill: #333131;
    transition: all 0.5s ease-out;
  }

`;

const Header = () => {
  const [open, setOpen] = useState(false);

  const { allStrapiService, allStrapiProject } = useStaticQuery(graphql`{
      allStrapiService {
          nodes {
              id
              iconSvg {
                  localFile {
                      publicURL
                  }
              }
              title
          }
      }
      allStrapiProject {
          nodes {
              id
              title
          }
      }

  }`);

  const ServiceLink = allStrapiService.nodes.map(item => {
    return {
      name: item.title,
      img: <Svg src={item.iconSvg.localFile.publicURL} />,
      link: `/services/${item.id}`
    };
  });

  const ProjectLink = allStrapiProject.nodes.map(item => {
    return {
      name: item.title,
      url: "/projects/" + item.id
    };
  });

  const LINKS1 = [
    {
      type: "dropdown",
      render: <CategoryDropdown data={ServiceLink} className="header" />,
      title: "Умный дом",
      mobileRender: <CategoryDropdownMobile title="Умный дом" data={ServiceLink} />
    },
    {
      type: "dropdown",
      render: <ProjectDropdown data={ProjectLink} />,
      title: "Проекты",
      mobileRender: <ProjectsDropdownMobile title="Проекты" data={ProjectLink} />
    },
    {
      type: "link",
      title: "Шоурум",
      link: "/showroom"
    },
    {
      type: "link",
      title: "Новости",
      link: "/news"
    },
    {
      type: "dropdown",
      render: <CompanyDropdown data={LINK3} />,
      title: "Компания",
      mobileRender: <CompanyDropdownMobile title="Компания" data={LINK3} />
    }
  ];

  const OpenMenu = (value) => {
    setOpen(value);
  };


  return (
    <>
      <ContainerHeader>
        <HeaderContainer>
          <Link to={"/"}>
            <Logo className="active adaptive-svg header" style={{ width: 209, height: 47, marginTop: 0 }} />
          </Link>
          <ContainerBtn>
            {
              LINKS1.map((item, count) =>
                item.type === "dropdown" ? item.render
                  :
                  <Link to={item.link}>
                    <Btn>
                      {item.title}
                    </Btn>
                  </Link>
              )
            }

          </ContainerBtn>

          <CalcPrice>
            <TextCalc>
              Рассчитать <br /> стоимость
            </TextCalc>
            <Calc className="adaptive-svg-calc header" style={{ marginTop: 0 }} />
          </CalcPrice>
          <MobileBtn openMenu={OpenMenu} />
        </HeaderContainer>
        {
          open === true ? (
            <div style={{ width: "100%", backgroundColor: "#4285F4" }}>
              <MenuGG
                style={{
                  width: "100%",
                  backgroundColor: "#4285F4",
                  fontSize: 24,
                  lineHeight: 48,
                  textTransform: "uppercase"
                }}
                mode="inline"
                theme="dark"
              >
                {

                  LINKS1.map((item, count) =>
                    item.type === "dropdown" ?
                      <SubMenu key={"sub" + count} title={item.title} style={{ marginBottom: 32, marginTop: 32 }}>
                        {item.mobileRender}
                      </SubMenu>
                      :
                      <Menu.Item
                        style={{ marginBottom: 32, marginTop: 32, backgroundColor: "#4285F4" }}
                        key={item.title + count + "d"}>
                        <ItemMenu1 to={item.link}>{item.title}</ItemMenu1>
                      </Menu.Item>
                  )

                }


              </MenuGG>
            </div>
          ) : (
            <></>
          )
        }
      </ContainerHeader>
    </>
  );
};

export default Header;
