import React from "react";
import styled from "styled-components";
import { Divider } from "antd";
import Logotype from "../../images/Logotype.svg";
import Instagram from "../../images/Instagram.svg";
import Telegram from "../../images/Telegram.svg";
import Facebook from "../../images/Facebook.svg";
import YouTube from "../../images/YouTube.svg";
import Design from "../../images/design.svg";
import Link from "gatsby-link";
import { ContainerFluid, ContainerFooter } from "../../ui/containers";
import { graphql, useStaticQuery } from "gatsby"




const Footer = ({toggleOpenModal}) => {

  const {allStrapiService} = useStaticQuery(graphql`{
      allStrapiService {
          nodes {
              id
              title
          }
      }
      
  }`)

  console.log(allStrapiService)
  return (
    <>
      <Container>
        <ContainerFluid>
          <BigButton onClick={toggleOpenModal}>получить консультацию</BigButton>
        </ContainerFluid>
        <ContainerFooter>
          <BodyOfFooter>
            <Logo src={Logotype} alt="Logo" />
            <Navigation>
              <LeftColumn>
                <BigString style={{ color: "#7E7E7E", marginBottom: "14px" }}>
                  умный дом
                </BigString>

                {allStrapiService.nodes.map((item, index) => (
                  <StringLink key={index} to={"/services/" + item.id}>
                    {item.title}
                  </StringLink>
                ))}
              </LeftColumn>
              <RightColumn>
                <BigStringLink to="#project">проекты</BigStringLink>
                <BigStringLink to="/business">для бизнеса</BigStringLink>
                <BigStringLink to="/news">новости</BigStringLink>
                <BigStringLink to="/showroom">шоурум</BigStringLink>
                <BigString style={{ color: "#7E7E7E" }}>компания</BigString>
                <StringLink to="/about">О нас</StringLink>
                <StringLink to="/cooperation">Сотрудничество</StringLink>
              </RightColumn>
            </Navigation>
            <Contacts>
              <BigStringLink to="/contacts" style={{ marginBottom: "10px" }}>
                контакты
              </BigStringLink>
              <String style={{ marginLeft: "0" }}>
                Москва, Ул.Барклая д6 стр5
                <br />
                (БЦ Барклай Плаза)
              </String>
              <String style={{ lineHeight: "30px", marginLeft: "0" }}>
                +7(913)4210574
              </String>
              <String style={{ marginLeft: "0" }}>info@intelbuilding.ru</String>
              <Icons>
                <a href="/">
                  <Icon src={Instagram} alt="Instagram" />
                </a>
                <a href="/">
                  <Icon src={Facebook} alt="Facebook" />
                </a>
                <a href="/">
                  <Icon src={Telegram} alt="Telegram" />
                </a>
                <a href="/">
                  <Icon
                    style={{ marginRight: "0" }}
                    src={YouTube}
                    alt="YouTube"
                  />
                </a>
              </Icons>
            </Contacts>
          </BodyOfFooter>
          <IntellectualRights>
            <MyDivider />
            <Abc>
              <div style={{ marginRight: "71%" }}>IntelBuilding 2021</div>
              <div>
                Designed by
                <img style={{ marginLeft: 10 }} src={Design} alt="Design" />
              </div>
            </Abc>
          </IntellectualRights>
        </ContainerFooter>
      </Container>
    </>
  );
};



export default Footer;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0D0C0C;
`;
const BigButton = styled.button`
  width: 100%;
  background-color: #4285F4;
  text-align: center;
  text-transform: uppercase;
  height: 150px;
  color: white;
  font-weight: 700;
  font-size: 22px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  transition-duration: .2s;

  &:hover {
    background-color: white;
    color: #4285F4;
  }

  @media screen and (max-width: 1000px) {
    height: 90px;
    font-size: 16px;
  }
`;

const BodyOfFooter = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  margin: 64px 0 71px 10%;
  align-items: flex-start;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    margin: 64px 10px 20px 10px;
  }
`;

const Logo = styled.img`
  margin-right: 12%;
  width: 179.5px;
  @media screen and (max-width: 1000px) {
    margin-right: 0;
    margin-bottom: 55px;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10%;
  width: 30%;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    margin-right: 0;
    margin-bottom: 20px;
    width: 90%;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 23px;
`;

const Icon = styled.img`
  height: 24px;
  margin-right: 45px;
`;

const StringLink = styled(Link)`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin-left: 10px;
  color: #FFFFFF;
  @media screen and (max-width: 1000px) {
    margin-bottom: 30px;
  }
`;
const String = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin-left: 10px;
  color: #FFFFFF;
  @media screen and (max-width: 1000px) {
    margin-bottom: 30px;
  }
`;

const BigString = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 46px;
`;

const BigStringLink = styled(Link)`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 46px;
  color: #FFFFFF;
`;

const IntellectualRights = styled.div`
  margin: 5px 0 76px 10%;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 46px;
  color: white;
  @media screen and (max-width: 1000px) {
    margin: 42px 10px 20px 10px;
  }
`;

const MyDivider = styled(Divider)`
  background-color: #3E3D3D;
  z-index: 2;
  margin: 0 11% 0 0;
  min-width: 0;
  width: auto;
  @media screen and (max-width: 1000px) {
    min-width: 100%;
    margin-bottom: 41px;
  }
`;

const Abc = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
