import React, {useState} from 'react';
import styled from "styled-components";
import { Button, Divider, notification} from "antd";
import {Input} from 'antd';
import Arrow from "../../assets/arrowBack.svg";
import {RequestAPI} from "../../api";

const {TextArea} = Input;


const MyInput = styled.input`
  background-color: transparent;
  padding: 40px 0;
  color: white;
  margin-left: ${props => props.margin || '119'}px;
  border: none;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  outline: none;

  &:focus {
    border-bottom: ${props => props.white ? " 2px solid #FFFFFF" : ' 1px solid #FFFFFF'};
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
`

const Wrapper = styled.div`
  width: 643px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 500px;
  }
  @media (max-width: 576px) {
    width: 400px;
  }
  @media (max-width: 427px) {
    width: 300px;
  }
  @media (max-width: 323px) {
    width: 200px;
  }

`

const Text = styled.div`
  font-size: 18px;
  margin: 40px 0;
  color: #ffffff;
  font-size: 18px;
`
const Area = styled(TextArea)`
  font-size: 18px;
  color: #ffffff;
  background-color: transparent;
  border: none;

  :active {
    border-color: #FFFFFF;
  }

  :focus {
    box-shadow: none;
    border: 1px solid #FFFFFF;
  }

  &:hover {
    border-color: #3e3e3e;
  }
`
const MyDivider = styled(Divider)`
  background-color: ${props => props.white ? '#FFFFFF' : '#3E3D3D'};
  z-index: 2;
  margin: 0;
`;

const AreaContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const AreaCollapse = styled.div`
  width: 100%;
  margin: 40px 0 40px 54px;
  @media (max-width: 576px) {
    margin: 20px 0 10px 0;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  transition-duration: .2s;
  color: #4285F4;
  &:hover{
    color:white;
  };
`;

const Formik = ({white}) => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const openNotification = (type, time = 300000) => {
        let config = {
            message: type === 'success' ?
                `Заявка успешно отправлена!`
                :
                type === 'info' ? `Заявка уже была отправлена!` : 'Ошибка отправки',
            style: {
                backgroundColor: '#0B0A0A',
                color: '#FFFFFFFF',
                width: 600,
            },
            description: type === 'success' ? 'Ожидайте, с вами скоро свяжется наш оператор.' :
                type === 'info' ? `Не волнуйтесь, наш оператор позвонит Вам. Вы сможете снова отправить заявку через 
                ${new Date(300000 - time).getMinutes() + 1} мин.` :
                    'Заполните необходимые поля, "Имя" и "Телефон"',
            placement: 'bottomRight',

        }
        notification[type](config);
    };


    const handleClick = async () => {
        const time = new Date().getTime() - window.localStorage.getItem('timeRequest');
        if (name === '' || phoneNumber === '') {
            openNotification('warning')
        }
        else
        {
            if (time >= 300000) {
                RequestAPI.SendForm(name, phoneNumber, message).then(result => console.log(JSON.stringify(result)))
                openNotification('success');
            }
            else
                openNotification('info', time);
        }
    }
    return (
      <>
        <Wrapper>
          <MyDivider white={white} />
          <InputContainer>
            <Text>Имя</Text>
            <MyInput
              onChange={(event) => setName(event.target.value)}
              white={white}
            />
          </InputContainer>
          <MyDivider white={white} />
          <InputContainer>
            <Text>Телефон</Text>
            <MyInput
              onChange={(event) => setPhoneNumber(event.target.value)}
              margin={"82"}
              white={white}
            />
          </InputContainer>
          <MyDivider white={white} />
          <AreaContainer>
            <Text>Сообщение</Text>
            <AreaCollapse>
              <Area
                onChange={(event) => setMessage(event.target.value)}
                rows={4}
              />
            </AreaCollapse>
          </AreaContainer>
          <MyDivider white={white} />
          <Div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 56,
            }}
          >
            <Btn
              onClick={handleClick}
              white={white}
              shape="circle"
              type="primary"
              size={"large"}
            >
              <Arrow />
            </Btn>
            <TextButton white={white}>
              <A onClick={handleClick}>ОТПРАВИТЬ</A>
            </TextButton>
          </Div>
        </Wrapper>
      </>
    );
};

export default Formik;

const A = styled.a`
  transition-duration: 0.2s;
  color: inherit;
  &:hover {
    color: white;
  }
`;

export const TextButton = styled.div`

  color: ${props => props.white ? '#ffffffff' : '#4285F4'};
  font-weight: bold;
  font-size: 16px;
  margin-left: 18px;
  @media (max-width: 600px) {
    font-size: 13px;
    margin-left: 15px;
  }
`;


export const Btn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.white ? "#ffffffff" : "#4285F4")};
  width: 62px;
  height: 62px;
  border: none;

  & > svg > path {
    fill: ${(props) => (props.white ? "#4285F4" : "#ffffffff")};
  }

  @media (max-width: 600px) {
    width: 49px;
    height: 49px;
  }
  @media (max-width: 323px) {
    & > svg {
      width: 10px;
      height: 19px;
    }
  }
`;
