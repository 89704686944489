import React from "react";
import styled from "styled-components";

import {ContainerHeader} from "./containers";
import Formik from "../components/Form/form";

const ModalDiv = styled.div`
  background: #4285F4;
  opacity: 0.9;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;

  padding-bottom: 130px;
  width: 100vw;
  height: 103vh;
  min-height: 120vh;
  z-index: 100;
  margin-top:-200px;
  &::-webkit-scrollbar {
    width: 1px;
    height: 2px;
  }

`

const TimesDix = styled.div`
  color: white;
  font-size: 50px;
  position: absolute;
  right: 48px;
  top: 162px;
  transform: scale(1.8);

  &:hover {
    cursor: pointer;
  }
  @media (min-width: 1668px) {
    top: 192px;
  }
  @media (max-width: 968px) {
    top: 162px;
    font-size: 30px;
    right: 15px;
  }
`

const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 300px;
  padding: 0 20px;
  @media (max-width: 1200px){
    overflow: hidden;
    margin-right: 120px;
  }
  @media (max-width: 968px) {
    margin-right: 0;
    overflow: inherit;
    display: block;
    margin-top: 160px;
  }
`

const TextBlock = styled.div`
  color: white;
  font-size: 20px;
  padding-right: 50px;
`

const TextHeader = styled.div`
  font-size: 70px;
  line-height: 110px;
  padding-bottom: 50px;

  @media (max-width: 968px) {
    font-size: 38px;
    line-height: 40px;
    margin-top: 250px;
  }
  @media (max-width: 568px) {
    font-weight: 300;
    font-size: 26px;
    margin-top: 230px;
  }
`

const TextContent = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  @media (max-width: 968px) {
    font-size: 16px;
  }
  @media (max-width: 568px) {
    font-weight: 300;
    font-size: 16px;
  }
`

const FormikWrapper = styled.div`
  margin-top: 170px;
  @media (max-width: 968px) {
    margin-top: 42px;
  }
`

const EmptyButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`

const Modal = (props) => {
    return(
        <ModalDiv>
            <ContainerHeader>
                <TimesDix>
                    <EmptyButton onClick={props.toggleOpenModal}>&times;</EmptyButton>
                </TimesDix>
                <ContentRow>
                    <TextBlock>
                        <TextHeader>
                            МЫ СВЯЖЕМСЯ <br/> С ВАМИ
                        </TextHeader>

                        <TextContent>+7 (910) 4210574</TextContent>
                        <TextContent>с ПН по ПТ 9:00 до 22:00</TextContent>
                    </TextBlock>
                    <FormikWrapper>
                        <Formik white={true}/>
                    </FormikWrapper>
                </ContentRow>
            </ContainerHeader>
        </ModalDiv>
    )
}

export default Modal;
