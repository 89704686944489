import React, {useState} from 'react'
import {Button} from "antd";
import Cross from '../../assets/cross.svg'
import OpenIcon from '../../assets/OpenMenu.svg'
import styled from "styled-components";

const Mobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;

  }
`;

/*const Menu1 = styled(Menu)`
  background-color: #4285F4;
`;*/

const Btn = styled(Button)`
  background-color: inherit !important;
  border: none;
`;


const MobileBtn = (props) => {
    const [open, setOpen] = useState(false);
    const HandleClick = (e) => {
        setOpen(!open);
        props.openMenu(!open);
    }

    return (
        <Mobile>
            <Btn onClick={HandleClick}>
                {
                    open === true ? (
                            <Cross style={{marginLeft: 2, marginTop: 0}}/>
                        ) :
                        (
                            <OpenIcon style={{marginLeft: 2, marginTop: 0}}/>
                        )
                }
            </Btn>
        </Mobile>
    )
}

export default MobileBtn;
