import axios from "axios";

export class RequestAPI {
  static async SendForm(name, phoneNumber, message) {
    let response = {
      error: false,
      msg: ""
    };
    try {
      window.localStorage.setItem("timeRequest", JSON.stringify(new Date().getTime()));
      let pld = (await axios.post(`https://intelbuildingrequest.herokuapp.com/api/form`, {
        name,
        phoneNumber,
        message
      }));
      console.log(pld);
    } catch (e) {
      response.error = true;
      response.msg = e;
      throw response;
    }

  }
}
