import styled from "styled-components";

export const ContainerL = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  @media(max-width:1268px){
    padding:10px;
  }
`

export const ContainerHeader = styled.div`
  max-width: 1280px;
  margin: 0 auto 0 auto;
  width: 100%;
`


export const ContainerFooter = styled.div`
max-width: 1600px;
margin: 0 auto;
width: 100%;
`;


export const ContainerCarousel = styled.div`
max-width: 1600px;
margin-left: auto;
width: 100%;
margin-right:auto;
`;

export const ContainerFluid = styled.div`
  width: 100%;
`
